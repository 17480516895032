@import '../../styles/global.scss';

.trending-container {

    background: $background;
    height: 100%;
    overflow: auto;
    width: 100%;
    
    h1, h2, h3 {
        color: lightgray;
        padding-top: 1rem;
    }

    .tabs {
        padding-inline: 2.5rem;
        padding-top: 1rem;
        font-size: 1.1rem;
        border-bottom: transparent;
    }

    .ant-tabs-nav-wrap {
        border-bottom: 1px solid black !important;
    }

    .ant-tabs-tab-btn {
        color: white;
        font-size: 1.1rem;
    }

    .pagination {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: 2rem;
        padding-inline: 2.5rem;
        padding-block: 1rem;
    }
    
    .ant-pagination-item, 
    .ant-pagination-prev, 
    .ant-pagination-jump-prev,
    .ant-pagination-next, 
    .ant-pagination-jump-next {
    
        background: $secondary;
        border: $secondary;
    
        a {
            color: $background;
            &:hover {
                color: white;
            }
        }
    }
    
    .ant-pagination-item-active {
    
        background: $primary;
        border: $primary;
    
        a {
            color: white;
        }
    }
    
    .ant-pagination-link-icon {
        color: white;
    }
    
    .ant-pagination-total-text {
        color: $secondary;
        padding-inline: 2rem;
    }
}