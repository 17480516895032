@import '../../styles/global.scss';

.video-list-container {
    
    color: gray;
    font-size: 1.2em;
    padding-inline: 2.5rem;

    .video-list-header {
        background: #101010;
        padding-block: 1.5rem;
        padding-inline: 1rem;
    }

    .video-list-content {
        
        padding-block: 1.5rem;
        padding-inline: 1rem;

        .title {
            color: white;
            margin-bottom: .2rem;
        }

        .artist {
            margin-bottom: .2rem;
        }

        .subline {

            align-items: center;
            display: flex;
            gap: .5rem;
            font-size: 1em;
            margin-top: .5rem;

            .vbadge {
                border-radius: 1rem;
                color: white;
                font-size: .8em;
                padding: .5rem .75rem;
            }

            .bpm {
                background: orange;
                font-size: .8em;
            }

            .genre {
                background: $primary;
                font-size: .8em;
            }

            .clean {
                background: green;
                font-size: .8em;
            }

            .dirty {
                background: red;
                font-size: .8em;
            }
        }

        .actions {

            display: flex;
            flex-direction: row;
            gap: .25rem;

            .play-button {
                cursor: pointer !important;
                font-weight: bold;
                font-size: 1.25em;
                color: green;
                padding: 0 .5rem;

                .play-container {
                    align-items: center;
                    border-radius: 11px;
                    border: 1px solid white;
                    background: #770000;
                    color: white;
                    display: flex;
                    height: 22px;
                    justify-content: center;
                    width: 22px;

                    &:hover {
                        background: #dd0000;
                    }
                }
            }
                
            .download-button {

                cursor: pointer !important;
                font-size: .8em;
                font-weight: bold;
                padding: 0;

                .download-container {

                    border-radius: 4px;
                    padding-inline: .5rem;
                    padding-block: .1rem;
                    color: white;
                    opacity: .8;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .video-list-header, .video-list-content {

        align-items: center;
        border-bottom: 1px solid $border;
        display: grid;
        gap: 1rem;
        grid-template-columns: auto 8rem 12rem 6rem 6rem 6rem;
    }
}