
.main-layout {

    display: flex;
    height: 100vh;

    .sidebar-toggle {

        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        padding: 0;
        border-radius: 100%;

        position: absolute;
        right: -12px;
        top: 64px;
    }
}

.ant-drawer-content-wrapper {
    height: 280px !important;
}