@import '../../styles/global.scss';

.subscriptions-container {

    background: $background;
    height: 100%;
    padding: 1.5rem;
    width: 100%;
    overflow: auto;

    h3 {
        color: $secondary;
        padding-inline: 2.5rem;
        padding-block: 1rem;
    }

    .subscriptions-list {
        justify-content: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        padding-inline: 2.5rem;
    }

    .credit-cards {
        
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 4rem;

        img {
            width: 16rem;
        }
    }
}