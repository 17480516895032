@import '../../styles/global.scss';

.catalog-container {

    background: $background;
    height: 100%;
    overflow: auto;
    width: 100%;
    
    h1, h2, h3 {
        // color: $primary;
        color: lightgray;
        padding-inline: 2.5rem;
        padding-top: 1rem;
    }
}