@import '../../styles/global.scss';

.login-container {

    height: 100vh;
    background: $background;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .side-image {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .login-form-container {

        align-items: center;
        display: flex;
        justify-content: center;
        padding: 3rem;
        
        .login-form {
            
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 24rem;
            width: 100%;

            .logo {
                height: 3rem !important;
                margin-bottom: 2rem;
            }

            .form {

                width: 100%;

                label {
                    color: $secondary;;
                    margin-bottom: .25rem;
                }

                .reset-line {

                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;

                    .forgot-password {
                        color: $primary;
                        padding: 0;
                    }
                }

                .signup-line {

                    align-items: center;;
                    color: $secondary;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    gap: .5rem;
                    
                    .signup {
                        color: $primary;
                        padding: 0;
                    }
                }
            }
        }


    }
}