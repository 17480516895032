@import '../../styles/global.scss';

.subscription-form-container {

    background: $background;
    align-items: center;
    display: flex;
    height: 90.70vh;
    justify-content: center;
    
    .header {
        
        color: $secondary;
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 1rem;
        text-align: left;
        width: 100%;
    }

    .card-form {
        
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 32rem;
        width: 100%;
        
        .subscription-plan {

            border: 1px solid $primary;
            border-radius: 4px;
            color: lightgray;
            display: flex;
            flex-direction: row;
            font-size: 1.25rem;
            justify-content: space-between;
            margin-bottom: 3rem;
            padding: 1rem;
            width: 100%;

            .name {
                font-weight: bold;
            }

            .price {
                color: $primary;
            }
        }

        .form {
            width: 100%;
            label {
                color: $secondary;
                margin-bottom: .25rem;
            }
            .agreement {
                margin-bottom: 1.5rem;
            }
        }

        .ant-btn-primary {

            &:disabled {
                opacity: .5;
                background: $primary;
                color: white;
            }
        }
    }

    .success-panel {
        
        align-items: center;
        border: 1px solid $border;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 32rem;
        padding: 2rem;
        width: 100%;

        .congratulatory-header {

            color: $secondary;
            font-size: 2em;
            font-weight: bold;
            margin-bottom: 2rem;
            text-align: center;
            width: 100%;
        }

        .congratulatory-message {

            color: $secondary;
            font-size: 1.2rem;
            margin-bottom: 1rem;
            text-align: center;

            .plan {
                color: $primary;
                font-size: 1.5em;
            }
        }

        .button-container {

            margin-top: 2rem;

        }
    }
}
