@import '../../styles/global.scss';

.register-form-container {

    background: $background;
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 3rem;

    .header {
        
        margin-bottom: 1rem;
        text-align: left;
        width: 100%;

        .signupto {
            color: $secondary;
            font-size: 2em;
            font-weight: bold;
        }
        
        .clubdjvideos {
            color: $secondary;
            font-size: 2.5em;
            font-weight: bold;
        }
    }

    .login-line {

        align-items: center;
        color: $secondary;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: .5rem;
        margin-bottom: 1rem;
        width: 100%;
        
        .login {
            color: $primary;
            padding: 0;
        }
    }
    
    .register-form {
        
        align-items: center;
        display: flex;
        flex-direction: column;
        max-width: 24rem;
        width: 100%;

        .logo {
            height: 3rem !important;
            margin-bottom: 2rem;
        }

        .form {
            width: 100%;
            label {
                color: $secondary;
                margin-bottom: .25rem;
            }
            .agreement {
                margin-bottom: 1.5rem;
            }
        }

        .ant-btn-primary {

            &:disabled {
                opacity: .5;
                background: $primary;
                color: white;
            }
        }
    }
}
