@import '../../styles/global.scss';

.crate-set-container {

    background: $background;
    height: 100%;
    overflow: auto;
    width: 100%;
    
    .crate-info {

        align-items: flex-start;
        display: flex;
        flex-direction: row;
        color: $secondary; 
        justify-content: flex-start;
        margin: 2.5rem;

        img {
            width: 10rem;
            height: 10rem;
            object-fit: cover;
        }

        .info {

            margin-left: 2rem;
            padding-block: 1rem;

            .name {
                color: $primary;
                font-size: 1.5em;
                font-weight: bold;
                margin-bottom: .5rem;
            }

            .count {
                font-size: .8rem;
                margin-bottom: .5rem;
            }

            .description {
                font-size: 1rem;
            }
        }
    }
}